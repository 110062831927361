// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

const initialSelectedNotification = () => {
  const item = window.localStorage.getItem('staff_notification_count')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : 0
}

const taskBoardCount = () => {
  const item = window.localStorage.getItem('task_board_count')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : 0
}

const taskOpenCount = () => {
  const item = window.localStorage.getItem('task_open_count')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : 0
}


export const StaffNotificationCount = createSlice({
  name: 'staff_notification_count',
  initialState: {
    staff_notification_count: initialSelectedNotification(),
    task_board_count : taskBoardCount(),
    task_open_count  : taskOpenCount()
  },
  reducers: {
    handleNotificationCount: (state, action) => {
      state.staff_notification_count = action.payload
      window.localStorage.setItem('staff_notification_count', parseInt(JSON.stringify(action.payload)))
    },
  handleTaskBoardCount: (state, action) => {
      state.task_board_count = action.payload
      window.localStorage.setItem('task_board_count', parseInt(JSON.stringify(action.payload)))
    },
  handleTaskOpenCount: (state, action) => {
      state.task_open_count = action.payload
      window.localStorage.setItem('task_open_count', parseInt(JSON.stringify(action.payload)))
    }
  }
})

export const {
  handleNotificationCount,
  handleTaskBoardCount,
  handleTaskOpenCount
} = StaffNotificationCount.actions

export default StaffNotificationCount.reducer
